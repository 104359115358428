import {initDataDog, isTestSession} from '@renofi/analytics';
import {initLogRocket} from '@renofi/analytics/src/logrocket2';
import {initSegment} from '@renofi/analytics/src/segment';
import {initSentry} from '@renofi/analytics/src/sentry';
import {initAccessibe} from '@renofi/analytics/src/accessibe';
import {getItem} from '@renofi/utils';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';

export const initAnalytics = () => {
  if (isTestSession()) {
    return;
  }

  initSegment();
  initAccessibe();

  if (getItem(UTM_COOKIE_KEYS.cookieconsent)) {
    initLogAnalytics();
  }
};

export const initLogAnalytics = () => {
  initLogRocket({parentDomain: 'https://dashboard.renofi.com'});
  initSentry();
  initDataDog({envs: ['staging']});
};
